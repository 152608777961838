import React, { useState } from 'react'
import Navbar from '../Navbar'
import '../App.css'
import home from '../Components/img/AEPS-Logo 1.png'

import bharat from '../Components/img/image 2.png'
import ndth from '../Components/img/image 3.png'
import Dmt from '../Components/img/DMT.png'
import first from '../Components/img/Group 4.png'
import third from '../Components/img/Group 5.png'
import second from '../Components/img/Group 6.png'
import ddd from '../Components/img/Rectangle 17.png'
import ggg from '../Components/img/Group 203.png'
import aji from '../Components/img/Group 203.png'
import { FaHandshakeAngle } from "react-icons/fa6";
import { IoMdLock } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import Fotter from './Fotter/Fotter'
import its from "../Components/img/its.png";
import thats from "../Components/img/thats.png"

const Home = () => {

  const [phoneNumber, setPhoneNumber] = useState('');
  const [outputMessage, setOutputMessage] = useState('');

  const showMessage = () => {
    // Display the message
    setOutputMessage(`Thank you for your inquiry, we will reach back to you shortly ${phoneNumber}`);

    // Clear the message after 3 seconds
    setTimeout(() => {
      setOutputMessage('');
    }, 3000);
  };

  const backgroundImageStyle = {
    backgroundImage: `url(../src/Components/img/Gradient (3).png)`, width: '100%',
    height: 'auto',
    backgroundSize: 'cover'
  };
  return (
    <div>
      <Navbar />



      <div style={{ marginTop: 150 }}>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
              <div style={{ display: "flex", justifyContent: 'center' }}>
                <img src={thats} className='abcd' style={{ width: '80%', objectFit: "scale-down", marginTop: -100 }} />
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
              <h5 style={{ textAlign: 'center' }}>Ticket Booking ho ya  Recharge ho ya Bill Payments,
                <br />
                ab Sab Kuch Payrupya <span style={{ color: '#F23033' }}>ke saath</span> </h5>
              <br />

              <h5 className='text-center' style={{ color: '#0A1759' }}>Simple | Easy | Quick | Reliable</h5>


              <div className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                <button className='btn' style={{ backgroundColor: '#0A1759', color: '#fff', borderRadius: '25px' }}>Become an Agent</button>

              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-12 '>
              <div style={{ display: "flex", justifyContent: 'center' }}>
                <img src={its} className='abcd2' style={{ width: '100%' }} />
              </div>
            </div>


          </div>
        </div>


        <div className='container pt-5  '>
          <div className='row mt-5'>
            <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
              <h1 style={{ fontWeight: '600', fontSize: '50px', width: '100%' }}>Services We <br />Offer</h1>


              <p className='mt-3 '>We at Payrupya are Determined to Deliver Fast & Reliable services to our Partners, Associates, Distributors and Retailers.

                <br />
                <br />
                All we do is to operate in good Faith and abide by the law of our mother country at every single check point, making our systems Compliant, Easy to use, Affordable and Secured </p>
            </div>

            <div className='col-2'></div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
              <div className='row'>
                <div className='col-6'>
                  <div className='card  abc'>
                    <div className='card-body'>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={bharat} style={{ width: '70%' }} />
                      </div>
                    </div>
                  </div>

                  <div className='card  abc py-3 mt-4'>
                    <div className='card-body'>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={ndth} style={{ width: '70%' }} />
                      </div>
                    </div>
                  </div>
                </div>




                <div className='col-6'>
                  <div className='card  abc py-2 '>
                    <div className='card-body'>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={home} style={{ width: '90%' }} />
                      </div>
                    </div>
                  </div>

                  <div className='card  py-4 abc mt-4'>
                    <div className='card-body'>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img className='py-2' src={Dmt} style={{ width: '40%' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>



        <div className='container mt-3 pt-5'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <h1 className='text-center' style={{ fontWeight: '600' }}>Our Numbers</h1>

              <p className='text-center' >With our legacy of over 6 Years in the business, we only care about <br /> the number of Faithful Relations and goodwill we have developed</p>
            </div>
          </div>


          <div className='row mt-4'>
            <div className='col-lg-11 m-auto'>
              <div className='row'>

                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                  <div className='card border-0' style={{ backgroundColor: '#F230330D' }}>
                    <div className='card-body'>

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={first} style={{ width: '50%' }} />
                      </div>


                      <h2 className='pt-4' style={{ color: '#F23033', textAlign: 'center', fontWeight: '600' }}>1200+</h2>


                      <p className='pt-1' style={{ textAlign: 'center' }}>Business Outlets</p>

                    </div>
                  </div>
                </div>



                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                  <div className='card border-0' style={{ backgroundColor: '#F230330D' }}>
                    <div className='card-body'>

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={third} style={{ width: '50%' }} />
                      </div>


                      <h2 className='pt-4' style={{ color: '#F23033', textAlign: 'center', fontWeight: '600' }}>INR 100Cr +</h2>


                      <p className='pt-1' style={{ textAlign: 'center' }}>Amount Transacted <br />Every Year</p>

                    </div>
                  </div>
                </div>




                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                  <div className='card border-0' style={{ backgroundColor: '#F230330D' }}>
                    <div className='card-body'>

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={second} style={{ width: '45%' }} />
                      </div>


                      <h2 className='pt-4' style={{ color: '#F23033', textAlign: 'center', fontWeight: '600' }}>INR 100Cr +</h2>


                      <p className='pt-1' style={{ textAlign: 'center' }}>Pin codes  Covered </p>

                    </div>
                  </div>
                </div>






              </div>
            </div>
          </div>
        </div>















        <div className='container mt-5'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <div className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                <button className='btn' style={{ backgroundColor: '#0A1759', color: '#fff', borderRadius: '25px' }}>Become an Agent</button>
              </div>
            </div>



            <div className='col-lg-12 col-sm-12 mt-5 bgimage' >

              <h1 className='ps-5 p-5' style={{ fontWeight: '600', color: '#fff' }}>Our Core <br /> Values</h1>


              <div className='row pb-5'  >
                <div className='col-lg-1 col-md-6 col-sm-12'> </div>
                <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className='btn w-100' style={{ backgroundColor: '#0F2070', color: '#fff' }}><FaHandshakeAngle className='me-2 fs-5' />
                    Commitment</button>
                  </div>
                </div>



                <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className='btn w-100' style={{ backgroundColor: '#0F2070', color: '#fff' }}><IoMdLock className='me-2 fs-5' />

                      Security</button>
                  </div>
                </div>



                <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className='btn w-100' style={{ backgroundColor: '#0F2070', color: '#fff' }}><FaRegUser className='me-2 ' />
                      Trust</button>

                  </div>
                </div>

              </div>

            </div>



          </div>
        </div>



        <div className='container mt-5'>
          <div className='row'>
            <div className='col-lg-4 col-sm-12'>
              <h4 className='pb-4' style={{ borderBottom: '3px solid #F23033' }}>Announcements</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 col-sm-12 mt-4'>
              <p  >Read about our latest happenings with our blogs and stay connected with our Payrupya Family!!</p>
            </div>
          </div>
        </div>





        <div className='container mt-5'>
          <div className='row'>
            <div className='col-lg-4 col-sm-12'>
              <div className='card'>
                <div className='card-header p-0'>
                  <img src={ddd} style={{ width: '100%' }} />
                </div>
                <div className='card-body'>
                  <h5>How to Add colors to your dumb business life ?</h5>



                  <div className='mt-3' style={{ display: 'flex', }}>
                    <h6 style={{ display: 'flex', alignItems: 'center', color: '#3F3F3F' }}>  <FaCalendarAlt className='me-2' /> 26/10/2024</h6>
                    <h6 className='ps-5' style={{ display: 'flex', alignItems: 'center', color: '#3F3F3F' }}>  <FaRegUser className='me-2' /> Admin</h6>
                  </div>
                </div>

                <div className='card-footer' style={{ display: 'flex', justifyContent: 'center' }}>
                  <button className='btn text-center'>View Complete Blog</button>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='card'>
                <div className='card-header p-0'>
                  <img src={ddd} style={{ width: '100%' }} />
                </div>
                <div className='card-body'>
                  <h5>How to Add colors to your dumb business life ?</h5>



                  <div className='mt-3' style={{ display: 'flex', }}>
                    <h6 style={{ display: 'flex', alignItems: 'center', color: '#3F3F3F' }}>  <FaCalendarAlt className='me-2' /> 26/10/2024</h6>
                    <h6 className='ps-5' style={{ display: 'flex', alignItems: 'center', color: '#3F3F3F' }}>  <FaRegUser className='me-2' /> Admin</h6>
                  </div>
                </div>

                <div className='card-footer' style={{ display: 'flex', justifyContent: 'center' }}>
                  <button className='btn text-center'>View Complete Blog</button>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='card'>
                <div className='card-header p-0'>
                  <img src={ddd} style={{ width: '100%' }} />
                </div>
                <div className='card-body'>
                  <h5>How to Add colors to your dumb business life ?</h5>



                  <div className='mt-3' style={{ display: 'flex', }}>
                    <h6 style={{ display: 'flex', alignItems: 'center', color: '#3F3F3F' }}>  <FaCalendarAlt className='me-2' /> 26/10/2024</h6>
                    <h6 className='ps-5' style={{ display: 'flex', alignItems: 'center', color: '#3F3F3F' }}>  <FaRegUser className='me-2' /> Admin</h6>
                  </div>
                </div>

                <div className='card-footer' style={{ display: 'flex', justifyContent: 'center' }}>
                  <button className='btn text-center'>View Complete Blog</button>
                </div>
              </div>
            </div>
          </div>
        </div>




        <section className='bgAb my-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 col-m-12'>
                <h6 className='text-center' style={{ color: '#EDEFF6' }}>Testimonials</h6>


                <h2 className='text-center' style={{ color: '#EDEFF6' }}>Don’t take our word Over 1000+ People Trusts Us</h2>


                <div style={{ display: 'flex', width: '100%', overflowX: 'scroll' }}>



                  <div className='card j1234   mt-4 p-1' style={{ backdropFilter: 'blur(20px)', width: '33%' }}>
                    <div className='card-body'>
                      <h5 className='text-white'>“It has been a Wonderfull Experience since I have Associated with Payrupya”</h5>


                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={aji} style={{ width: '50px', borderRadius: '20px' }} />

                        <div >
                          <h6 className='mt-2 text-white ps-4'>Rahul Sharma <br /> <span> Retailer - japan</span></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  <button className='btn' style={{ backgroundColor: 'white', color: '#F23033', borderRadius: '20px', padding: '3px 22px' }}>Become a Retailer</button>
                </div>
              </div>
            </div>
          </div>
        </section>




        <div className='container mt-5'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
              <h2 style={{ fontWeight: '600' }}>Become our Business <br /> Partner and Earn Upto<br /> <span style={{ color: '#F23033' }}>Rs 1 Lakh Per Month</span> </h2>


              <button className='btn mt-4 llb' style={{ backgroundColor: '#0A1759', color: '#fff', width: '50%' }}>Contact Us</button>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
              <img src={ggg} className='llb' style={{ width: '70%' }} />
            </div>
          </div>
        </div>
      </div>


      <Fotter />

    </div>
  )
}

export default Home
import React from 'react'
import { IoCheckmarkDone } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosArrowRoundForward } from "react-icons/io";
import pay from '../../src/Components/img/image 10.png'
import deva from '../../src/Components/img/image 10.png'
import Navbar from '../Navbar';
import Fotter from './Fotter/Fotter';


const Charges = () => {
  return (
    <div className='bg-white'>
        <Navbar/>
        <div className='container pt-5 mt-5'>
            <div className='row'>
                <div className='col-lg-7 col-md-12 col-sm-12 m-auto'>
                     <p className='text-center' style={{color:'#474747'}}>Pricing</p>

                     <h2 className='text-center' style={{fontWeight:'600'}}>So, What does it Costs ?</h2>

                     <p className='text-center' style={{color:'#474747'}}>So, we’re saving you with the hassle to move around for every service you need , and We are not overcharging you for the same. Now you can deliver best Facilities to your customers at minimum cost</p>
                </div>
            </div>
        </div>




        <div className='container pt-5'>
            <div className='row'>
                <div className='col-lg-4 col-md-12 col-sm-12 mt-3'>
                    <div className='card'  style={{border:'1px solid #000'}}>
                        <div className='card-body'>
                            <h4 className='py-2' style={{borderBottom:'1px solid #E2E2E2'}}>Retailer</h4>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Access to All Services</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>Business Hours Support</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Instant Resolution</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Referral Cashback </span> <br/><br/>

                            <span > <IoCloseOutline className='me-2' style={{color:'#1FB63E'}}/>
                            Downline Benefit </span> <br/><br/>

                            <span > <IoCloseOutline className='me-2' style={{ }}/>
                            Downline Commission </span> <br/><br/>



                            <div className='mt-3' style={{display:'flex', justifyContent:'space-between'}}>
                                <h4 style={{fontWeight:'600'}}>INR 500/-</h4>


                                <button className='btn btn-dark'>Get Started <IoIosArrowRoundForward  className='ms-2'/>
</button>
                            </div>
                        </div>
                    </div>

                </div>



                <div className='col-lg-4 col-md-12 col-sm-12 mt-3'>
                    <div className='card'  style={{border:'1px solid #000'}}>
                        <div className='card-body'>
                            <h4 className='py-2' style={{borderBottom:'1px solid #E2E2E2'}}>Distributor</h4>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Access to All Services</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>Business Hours Support</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Instant Resolution</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Referral Cashback </span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Downline Benefit </span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Downline Commission </span> <br/><br/>



                            <div className='mt-3' style={{display:'flex', justifyContent:'space-between'}}>
                                <h4 style={{fontWeight:'600'}}>INR 2,500/-</h4>


                                <button className='btn btn-dark'>Get Started <IoIosArrowRoundForward  className='ms-2'/>
</button>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='col-lg-4 col-md-12 col-sm-12 mt-3'>
                    <div className='card'  style={{border:'1px solid #000'}}>
                        <div className='card-body'>
                            <h4 className='py-2' style={{borderBottom:'1px solid #E2E2E2'}}>Master Distributor</h4>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Access to All Services</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>Business Hours Support</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Instant Resolution</span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Referral Cashback </span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Downline Benefit </span> <br/><br/>

                            <span > <IoCheckmarkDone className='me-2' style={{color:'#1FB63E'}}/>
                            Downline Commission </span> <br/><br/>



                            <div className='mt-3' style={{display:'flex', justifyContent:'space-between'}}>
                                <h4 style={{fontWeight:'600'}}>INR 2,500/-</h4>


                                <button className='btn btn-dark'>Get Started <IoIosArrowRoundForward  className='ms-2'/>
</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


         <section className='mt-5'>
        <div className='container py-5 abcef'>
            <div className='row'>
                <div className='col-lg-12 col-sm-12'>
                    <h2 className='text-center text-white py-4' style={{fontWeight:'600'}}>Start your Success <br/>Story with Us</h2>
                     
                     <div  style={{display:'flex', justifyContent:'center'}}>
                    <button className='btn text-white px-5' style={{backgroundColor:'#F23033', }}>Get Started</button>
                </div>
                </div>
            </div>
        </div>

        </section>





        <section className='pt-5'>
        <div className='container mt-5  '  style={{backgroundColor:'#EFF7FF'}}>
            <div className='row'>
                <div className='col-1'></div>
                <div className='col-lg-6 col-md-6 col-sm-12 pt-5'>
            <p style={{color:'#F23033'}}>What’s Stopping You ?</p>

            <h5 className='my-4' style={{fontWeight:'600'}}>5000+ Individuals have Already <br/>Started their Success Journey with Us</h5>

            
                    <button className='btn text-white px-5 mb-4' style={{backgroundColor:'#F23033', }}>Get Started</button>
                
                </div>


                {/* <div className='col-lg-1 col-md-6 col-sm-12 '></div> */}
                <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div>
                <img src={pay} className='pay' style={{width:'100%', marginTop:'-60px'}}/>
              </div>
                
                </div>
            </div>
        </div>

        </section>
      



        <section className='pt-5'>
        <div className='container p-5   '  style={{backgroundColor:'#EFF7FF'}}>
           <div className='row'>
            <div className='col-lg-12 col-sm-12'>
                <h2 style={{fontWeight:'600'}}>Hear it from the <br/>people of Payrupya</h2>

                <p className='mt-5' style={{color:'#1e1e1e', fontWeight:'500'}}>We Take pride in considering all of our retailers, Business Partners and  Distributors as a part of our family, we call them people of Payrupya, hear their success stories directly from them.</p>
            </div>
           </div>




           <div className='row'>
            <div className='col-lg-4 col-sm-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <img src={deva} style={{width:'60px',borderRadius:'12px'}}/>

                            <div className='ps-3'>
                                <h6 className='mb-0 mt-2' style={{fontWeight:'600'}}>Rahul Sharma</h6>
                                <span className='pt-0' style={{fontSize:'12px'}}>Retailer - Punjab</span>
                            </div>
                        </div>



                        <p className='mt-4' style={{color:'#2D4356',  fontSize:'14px'}}>Getting Associated with Payrupya has been the most wonderful experience for me, as their simple and easy to use portal has helped me to maximize my earning potential now i have 2 employees and a office of my own</p>

                        <div style={{display:'flex', justifyContent:'end'}}>
                            <i className='btn ' style={{backgroundColor:'#CFDBE8', fontWeight:'600', fontSize:'14px'}}># peoples of Payrupya</i>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <img src={deva} style={{width:'60px',borderRadius:'12px'}}/>

                            <div className='ps-3'>
                                <h6 className='mb-0 mt-2' style={{fontWeight:'600'}}>Rahul Sharma</h6>
                                <span className='pt-0' style={{fontSize:'12px'}}>Retailer - Punjab</span>
                            </div>
                        </div>



                        <p className='mt-4' style={{color:'#2D4356',  fontSize:'14px'}}>Getting Associated with Payrupya has been the most wonderful experience for me, as their simple and easy to use portal has helped me to maximize my earning potential now i have 2 employees and a office of my own</p>

                        <div style={{display:'flex', justifyContent:'end'}}>
                            <i className='btn ' style={{backgroundColor:'#CFDBE8', fontWeight:'600', fontSize:'14px'}}># peoples of Payrupya</i>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <img src={deva} style={{width:'60px',borderRadius:'12px'}}/>

                            <div className='ps-3'>
                                <h6 className='mb-0 mt-2' style={{fontWeight:'600'}}>Rahul Sharma</h6>
                                <span className='pt-0' style={{fontSize:'12px'}}>Retailer - Punjab</span>
                            </div>
                        </div>



                        <p className='mt-4' style={{color:'#2D4356',  fontSize:'14px'}}>Getting Associated with Payrupya has been the most wonderful experience for me, as their simple and easy to use portal has helped me to maximize my earning potential now i have 2 employees and a office of my own</p>

                        <div style={{display:'flex', justifyContent:'end'}}>
                            <i className='btn ' style={{backgroundColor:'#CFDBE8', fontWeight:'600', fontSize:'14px'}}># peoples of Payrupya</i>
                        </div>
                    </div>
                </div>
            </div>
           </div>
        </div>

        </section>



        <section>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-6 col-sm-12 m-auto'>
                        <h2 className='text-center' style={{fontWeight:'700'}}>Frequently Asked <br/> Questions <span style={{color:'#F23033'}}>(FAQs)</span></h2>










                        <div>
                        <div class="accordion mt-5" id="accordionExample">
   
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      What is DMR?
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       <p>What is DMR?</p>
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
       What is AEPS?
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>What is AEPS?</p>
        </div>
    </div>
  </div>




  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      How do I get mobile & DTH recharged?
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>How do I get mobile & DTH recharged?</p>
        </div>
    </div>
  </div>



  <div class="accordion-item">
    <h2 class="accordion-header" id="heading5">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
      How do I get my bills paid?
            </button>
    </h2>
    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>How do I get my bills paid?</p>
        </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="heading6">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
      How does PAN CARD service work with Payrupya?
            </button>
    </h2>
    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>How does PAN CARD service work with Payrupya?</p>
        </div>
    </div>
  </div>
</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <Fotter/>
    </div>
  )
}

export default Charges

import React from 'react'
import Home from './Components/Home'
import Navbar from './Navbar'
import ABc from './Components/ABc'
import Aboutus from './Components/Abouts/Aboutus'
import Charges from './Components/Charges'
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Serves from './Components/Serves'
import AntivirusPolicy from './Components/AntivirusPolicy'
import PrivacyPolicy from './Components/PrivacyPolicy'
import UserLicenseAgreement from './Components/UserLicenseAgreement'
import RefundPolicy from './Components/RefundPolicy'
import EnverPolicy from './Components/EnverPolicy'
import Login from './Components/Login/Login'

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/About" element={<Aboutus/>}/>
        <Route path="/Pricing" element={<Charges/>}/>
        <Route path="/Contact_us" element={<ABc/>}/>
        <Route path="/Charge" element={<Charges/>}/>
        <Route path="/service" element={<Serves/>}/>
        <Route path="/AntivirusPolicy" element={<AntivirusPolicy/>}/>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
        <Route path="/UserLicenseAgreement" element={<UserLicenseAgreement/>}/>
        <Route path="/RefundPolicy" element={<RefundPolicy/>}/>
        <Route path="/EnvironmentalPolicy" element={<EnverPolicy/>}/>
        <Route path='/Login' element={<Login/>}/>
      </Routes> 

       {/* <AntivirusPolicy/> */}

       {/* <PrivacyPolicy/> */}

       {/* <UserLicenseAgreement/> */}
    </div>
  )
}

export default App
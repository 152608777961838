import React from 'react'
import { FaRegCalendarCheck } from "react-icons/fa";
import { FaHandHoldingWater } from "react-icons/fa";
import { CiDiscount1 } from "react-icons/ci";

import img2 from '../Components/img/revenue-operation-collage-removebg-preview 1.png'
import Fotter from './Fotter/Fotter';
import Navbar from '../Navbar';

const Serves = () => {
  return (
    <div>

       <Navbar/>


<section className='mt-5' style={{backgroundColor:'#081246'}}>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-lg-5 col-sm-12 mt-5'>
               <h2 className='text-white'> Now Transferring Funds Across India is Hassle Free with Payrupya’s Domestic Money Transfer Services
               </h2>
                <button className='btn mt-3 my-3' style={{backgroundColor:'#F23033', color:'#fff', padding:'10px 20px'}}>Become Partner</button>
                <p className='mt-3 text-white'>Payrupya's domestic money transfer service in India excels with its instant transactions, robust security measures, and user-friendly interface. With competitive fees and widespread accessibility, it ensures seamless and efficient fund transfers across the country.</p>
             
                 
                </div>

                <div className='col-lg-2 '>

                </div>
                <div className='col-lg-4 col-sm-12'>
                
                <img src={img2} style={{width:'100%'}}/>
             
                 
                </div>
            </div>


            <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                <div className='mt-3'>
                    <button className='btn py-3 abcd1 w-100' style={{background: '#122BA361', color:'#fff' ,padding:'8px 12px', fontSize:'20px'}}><FaRegCalendarCheck className='me-3' />24*7 Available</button>
                  </div>
                </div>

                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                <div className='mt-3'>
                    <button className='btn py-3 abcd1 w-100' style={{background: '#122BA361', color:'#fff' ,padding:'8px 12px', fontSize:'20px'}}><CiDiscount1 className='me-3 fs-4' />Most Affordable</button>
                  </div>
                </div>


                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                <div className='mt-3'>
                    <button className='btn py-3 abcd1 w-100' style={{background: '#122BA361', color:'#fff' ,padding:'8px 12px', fontSize:'20px'}}><FaHandHoldingWater className='me-3 fs-4' />Hassle Free</button>
                  </div>
                </div>
            </div>
        </div>
        </section>


        
      <section className="mt-5 mb-5 have" style={{ backgroundImage: `url('img/Group 18.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 m-auto mt-5">
              <h2 className="text-center" style={{ fontWeight: 600 }}>Frequently Asked <br/> Questions <span style={{color:'#F23033', fontWeight:'600'}}> (FAQs) </span></h2>

              <div className="accordion mt-5" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo11">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo11" aria-expanded="false" aria-controls="collapseTwo11">
                      <span>What is DMR?</span>
                    </button>
                  </h2>
                  <div id="collapseTwo11" className="accordion-collapse collapse" aria-labelledby="headingTwo11" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>Who can Become DMT Agent ?</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>What is AEPS?</span>
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>In Which bank accounts I can send money ?</span>
                    </div>
                  </div>
                </div> 
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree4">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree4" aria-expanded="false" aria-controls="collapseThree4">
                      <span>How do I get mobile & DTH recharged?</span>
                    </button>
                  </h2>
                  <div id="collapseThree4" className="accordion-collapse collapse" aria-labelledby="headingThree4" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>How can I change details in my PAN Card ?</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree5">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree5" aria-expanded="false" aria-controls="collapseThree5">
                      <span>How do I get my bills paid?</span>
                    </button>
                  </h2>
                  <div id="collapseThree5" className="accordion-collapse collapse" aria-labelledby="headingThree5" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>How to apply for new PAN Card ?</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree6">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree6" aria-expanded="false" aria-controls="collapseThree6">
                      <span>How does PAN CARD service work with Payrupya?</span>
                    </button>
                  </h2>
                  <div id="collapseThree6" className="accordion-collapse collapse" aria-labelledby="headingThree6" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>How can I withdraw money using aadhar Card ?</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




<div className='mt-5'>
    <Fotter/>
</div>
    </div>
  )
}

export default Serves
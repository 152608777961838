import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { NavLink } from 'react-router-dom';


const Fotter = () => {
    return (
        <section className='Gajju2 mt-5' style={{ paddingTop: 40 }}>
            <div className='Gajju' style={{ paddingTop: 40 }}>
                <div className='Gajju1' style={{ paddingTop: 40 }}>
                    <div className='container mt-5'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='mt-5'>
                                    <h5 className='text-white'>Our Services</h5>
                                    <ul className='ps-0 ' style={{ color: '#FFFFFFB2', listStyle: 'none', lineHeight: '35px' }}>
                                        <li>BBPS</li>
                                        <li>AEPS</li>
                                        <li>Travels Booking</li>
                                        <li>NSDL Pan Card</li>
                                        <li>CMS Bill Payments</li>
                                    </ul>
                                </div>


                                <div>
                                    <h4 className='text-white mt-4'>Useful Links</h4>
                                    <ul className='  ps-0  ' style={{ color: '#FFFFFFB2', listStyle: 'none', fontWeight: '400', lineHeight: '35px' }}>
                                        <li>About Us</li>
                                        {/* <li>Charges & Commissions</li> */}
                                        <li>Contact Us</li>
                                        <li>Help & Support</li>
                                        <li style={{ color: '#E9CF1D' }}>Become Partner</li>
                                    </ul>
                                </div>

                            </div>






                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div className='mt-5'>
                                    <h5 className='text-white'>Contact Us</h5>
                                    <ul className='ps-0' style={{ color: '#FFFFFFB2', listStyle: 'none', lineHeight: '40px' }}>
                                        <li><IoIosCall className='me-2 text-white fs-4' /> +91 995 000 7764</li>
                                        <li> <CiMail className='me-2 text-white fs-4' /> abc@gmail.com</li>
                                        <li> <IoLocationSharp className='me-2 text-white fs-4' /> 72, Ganesh Nagar-4, Nadi Ka <br /> <span className='ps-4   '> Phatak, Murlipura, Jaipur </span></li>

                                    </ul>
                                </div>


                                <div>
                                    <h4 className='text-white mt-4'>Follow Us On</h4>
                                    <div>

                                        <FaInstagram style={{ color: '#E9CF1D', fontSize: '25px' }} />
                                        <CiLinkedin className='mx-2' style={{ color: '#E9CF1D', fontSize: '30px' }} />
                                        <FaFacebookSquare className='me-2' style={{ color: '#E9CF1D', fontSize: '25px' }} />
                                        <FaYoutube className='mx-2' style={{ color: '#E9CF1D', fontSize: '30px' }} />



                                    </div>


                                    <h4 className='text-white mt-4'>Download Our App</h4>
                                </div>

                            </div>


                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='mt-5'>
                                    <h5 className='text-white'>Policies & Legal</h5>


                                    <ul className='ps-0' style={{ color: '#FFFFFFB2', listStyle: 'none', lineHeight: '35px' }}>
                                        <li><NavLink to="/AntivirusPolicy">Antivirus Policy</NavLink></li>
                                        <li><NavLink to="/PrivacyPolicy">Privacy Policy</NavLink></li>
                                        <li><NavLink to="/RefundPolicy">Refund Policy</NavLink></li>
                                        <li><NavLink to="/UserLicenseAgreement">User License Agreement</NavLink></li>
                                        <li><NavLink to="/EnvironmentalPolicy">Environmental Policy</NavLink></li>
                                        <li>Dispute Resolution Policy</li>
                                        <li>Settlement Policy</li>
                                        <li>BRTR Policy</li>
                                        <li>Information Security Policy</li>
                                        <li>NSM Policy</li>
                                        <li>Media Disposal Policy</li>
                                        <li>P&E Control Policy</li>
                                        <li>3rd Party Policy</li>
                                    </ul>

                                </div>



                            </div>
                        </div>
                    </div>


                    <div className='container-fluid py-3' style={{ backgroundColor: '#211562' }}>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span className='text-white text-center'>Designed, Developed and Maintained with ❣️ by <span style={{ color: '#E9CF1D' }}>  KGI Industries LLP </span>| 2024 ©  All Rights Reserved by Envitech Solutions Pvt Ltd.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Fotter
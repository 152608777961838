import React from 'react'
import { FaUser } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { FaRegMessage } from "react-icons/fa6";
import { IoIosCall } from "react-icons/io";
import AB from '../Components/img/abc.png'
import '../App.css'
import { CiMail } from "react-icons/ci";
import { IoLocationSharp } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import first from "../../src/Components/img/Union.png"
import Fotter from './Fotter/Fotter';
import Navbar from '../Navbar';

const ABc = () => {
  return (
    <div>
      <Navbar/>
        <div className='container' style={{marginTop:120}}>
            <div className='row'>
                <div className='col-2'> </div>
                <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
                    <div className='card'>
                        <div className='card-body'>
                            <h2>Let’s Get <br/> <span style={{color:'#F23033', fontWeight:'600'}}> Connected </span></h2>

                            <span>Fill out this contact form and our executives will reach you within 24 hours</span>



                            <select class="form-select mt-3 py-2" aria-label="Default select example" style={{boxShadow:'none', backgroundColor:'#FCF4F4', border:'none',color:'#C7AEAE'}}>
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                </select>



                                <div className='mt-3'>
                                <div class="input-group mb-3">
                                    <span class="input-group-text border-0" id="basic-addon1" style={{backgroundColor:'transparent', background:'#FCF4F4'}}><FaUser style={{color:'#F23033'}}/></span>
                                    <input type="text" class="form-control border-0  py-2" placeholder="My name is..." aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', background:'#FCF4F4',boxShadow:'none'}}/>
                                    </div>


                                    <div class="input-group mb-3">
                                    <span class="input-group-text border-0" id="basic-addon1" style={{backgroundColor:'transparent', background:'#FCF4F4'}}><IoMdCall style={{color:'#F23033'}}/></span>
                                    <input type="number" class="form-control border-0  py-2" placeholder="My Contact Number is..." aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', background:'#FCF4F4',boxShadow:'none'}}/>
                                    </div>





                                    <div class="input-group mb-3">
                                    <span class="input-group-text border-0" id="basic-addon1" style={{backgroundColor:'transparent', background:'#FCF4F4'}}><FaRegMessage style={{color:'#F23033'}}/></span>
                                    <input type="text" class="form-control border-0  py-2" placeholder="My Email address is..." aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', background:'#FCF4F4',boxShadow:'none'}}/>
                                    </div>


                                      <div>
                                        <div class="form-floating">
                                    <textarea class="form-control" placeholder="Enter the message..." id="floatingTextarea2"  rows="5"   cols="6"  style={{boxShadow:'none', backgroundColor:'#FCF4F4', color:'#C7AEAE'}}></textarea>
                                    <label for="floatingTextarea2" style={{color:'#C7AEAE'}}>Enter the message...</label>
                                    </div>
                                      </div>


                                     <div className='mt-3'>
                                        <button className='btn w-100' style={{backgroundColor:'#F23033', color:'#fff',}}>Submit</button>
                                     </div>
                                </div>
                        </div>
                    </div>
                    
                     </div>


                <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
                    <div className='card'>
                        <div className='card-body'>
                        <h2  >Reach Us out <br/> </h2> 
                          
                          <div className='row mt-4'>
                            <div className='col-2 mt-3'>
                                <div className='py-2' style={{backgroundColor:'#FFD2CE',textAlign:'center', borderRadius:'8px'}}>
                                <IoIosCall style={{color:'#F23033', fontSize:'22px' }}/>

                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='py-2'  > 

                                <h6 style={{color:'#7E7289'}}>Contact Number</h6>
                               <h5> +91 123456789</h5>
                                </div>
                            </div>
                          </div>



                          <div className='row mt-2'>
                            <div className='col-2 mt-3'>
                                <div className='py-2' style={{backgroundColor:'#FFD2CE',textAlign:'center', borderRadius:'8px'}}>
                                <CiMail style={{color:'#F23033', fontSize:'22px' }}/>

                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='py-2'  > 

                                <h6 style={{color:'#7E7289'}}>E-Mail Address </h6>
                               <h5> admin@gmail.com</h5>
                                </div>
                            </div>
                          </div>

                          <div className='row mt-2'>
                            <div className='col-2 mt-3'>
                                <div className='py-2' style={{backgroundColor:'#FFD2CE',textAlign:'center', borderRadius:'8px'}}>
                                <IoLocationSharp style={{color:'#F23033', fontSize:'22px' }}/>

                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='py-2'  > 

                                <h6 style={{color:'#7E7289'}}>E-Mail Address </h6>
                               <h5> 72, Ganesh Nagar-4, Nadi Ka Phatak, Murlipura, Jaipur</h5>
                                </div>
                            </div>
                          </div>




                                          
                    




                          <div>

                          </div>

                        


 
                        </div>

                        <div className='mt-4'>
                                                <img src={AB}  style={{width:'100%'}}/>
                                            </div>
                    </div>
                    
                     </div>
            </div>
        </div>
   {/* <img src={first}/> */}
     <Fotter/>
    </div>
  )
}

export default ABc
import React from 'react'
import Fotter from './Fotter/Fotter'
import Navbar from '../Navbar'

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar/>
      <div>
      <section style={{backgroundColor:'#081246'}}>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-lg-12 col-sm-12 text-center py-5'>
                    <h6 style={{color:'#FFFFFFB2'}}>Policies</h6>
                        <h2 className='my-4' style={{color:'#fff'}}>Privacy Policy</h2>
                    <h6 style={{color:'#FFFFFFB2'}}>Last Updated : 11 September 2023</h6>
                </div>
            </div>
        </div>
      </section>


      <div className='container mt-5'>
        <div className='row'>
            <div className='col-lg-12 col-sm-12'>
 
                <h4 className='my-4'>Effective Date: Latest Update Date
</h4>

                <span>
                Envitech Solutions and Technologies Private Limited ("Envitech," "we," "us," or "our") values the privacy and security of your personal information and is committed to protecting it in accordance with various regulations and standards, including but not limited to ISO/IEC 27001/2, GDPR (General Data Protection Regulation), and applicable laws of India. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information when you use our software portal and related services.
                </span>



                <h3 className='my-4'>1. Information We Collect:</h3>
                <h4 className='my-4'>1.1. Personal Information:
</h4>

                <span>
                This policy applies to all employees, contractors, third-party vendors, and any other individualswho have access to the organization's information systems and devices.
                </span>

                <h3 className='mt-4'>2. Antivirus Software Deployment</h3>
                <h5 className='mt-4'>2.1 Selection and Licensing</h5>
                <span className='mt-4'>- When you interact with our software portal and services, we may collect various types of personal information, including:
  - Contact information: such as your name, address, email address, and phone number.
  - Identification information: such as PAN card details, Aadhaar number, date of birth, and government-issued identification numbers.
  - Financial information: such as payment card details, bank account information, and transaction history.
  - Utility bill details: necessary for utility bill payments and fulfillment services.
  - Other relevant information: required for providing specific services or complying with legal obligations.

</span>






<h5 className='mt-4'>1.2. Usage Information:</h5>

<span className='mt-4'>
- We automatically collect certain information about your usage of our software portal, including:
  - IP address, device information, browser type, and operating system.
  - Browsing activities, pages visited, and interactions with our platform.
  - Log data and analytics to analyze trends, administer the platform, and improve user experience.
  <br/>
1.3. Cookies and Similar Technologies:
- We may use cookies, web beacons, and other tracking technologies to enhance user experience, personalize content, and analyze usage patterns. You have the option to control the use of cookies through your browser settings.
</span>



<h5 className='mt-4'>2. How We Use Your Information:</h5>

<span className='mt-4'>2.1. Service Provision:
- We use the information collected to provide, maintain, and improve our software portal and services, including:<br/>
  - Processing transactions, fulfilling service requests, and delivering relevant content.
  - Customizing user experience, addressing technical issues, and optimizing performance.
  - Providing customer support, responding to inquiries, and communicating service updates.
</span>



<h5 className='mt-4'>2.2. Legal Compliance:</h5>

<span className='mt-4'> 
- We may process personal information to comply with legal obligations, regulatory requirements, or enforceable governmental requests, including:<br/>
  - Anti-money laundering (AML) regulations, Know Your Customer (KYC) requirements, and tax compliance.
  - Investigating fraudulent activities, preventing unauthorized access, and ensuring data security.
  - Responding to legal proceedings, subpoenas, or court orders as required by law.
</span>



<h5 className='mt-4'>3. Information Sharing and Disclosure:</h5>

<span className='mt-4'>

3.1. Service Providers:
- We may share personal information with trusted third-party service providers, vendors, or business partners who assist us in delivering our services, including:
  - Payment processors, financial institutions, and technology providers.
  - Identity verification services, data storage facilities, and cybersecurity solutions.
  - Marketing agencies, analytics providers, and communication platforms.
</span>



<h5 className='mt-4'>3.2. Legal Requirements:</h5>

<span className='mt-4'>
- We may disclose personal information when required by law, legal proceedings, or governmental regulations, including:<br/>
  - Compliance with judicial orders, subpoenas, or law enforcement requests.
  - Protection of legal rights, property, or safety of Envitech, its users, or third parties.
  - Investigation and prevention of fraud, security breaches, or unauthorized activities.


</span>



<h5 className='mt-4'>3.3. Business Transfers:</h5>

<span className='mt-4'>- In the event of a merger, acquisition, or sale of assets, personal information may be transferred as part of the transaction. We will notify affected individuals and obtain their consent if required by applicable laws.</span>





<h5 className='mt-4'>4. Data Security:</h5>

<span className='mt-4'>

4.1. Information Security Measures:
- We implement robust technical, administrative, and physical security measures to protect personal information against unauthorized access, disclosure, alteration, or destruction. These measures include:
  - Encryption techniques, access controls, and secure transmission protocols.
  - Regular security assessments, vulnerability scans, and penetration testing.
  - Employee training programs, confidentiality agreements, and security awareness initiatives.


</span>



<h5 className='mt-4'>4.2. Data Retention and Disposal:</h5>

<span className='mt-4'>- We retain personal information only for the duration necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Upon expiration of the retention period, we securely dispose of or anonymize data in accordance with our data retention policies.<br/>
<br/>
4.3. Third-Party Security:
- We evaluate the security practices of third-party service providers and ensure contractual safeguards are in place to protect personal information entrusted to them.</span>





<h5 className='mt-4'>5. Your Rights and Choices:</h5>

<span className='mt-4'>

5.1. Access and Control:
- You have the right to access, correct, update, or delete your personal information held by us. You may exercise these rights by contacting us using the information provided in this Privacy Policy.
<br/>
5.2. Consent Management:
- You can manage your consent preferences for marketing communications, cookies, and tracking technologies through the settings available on our software portal or by contacting us directly.
<br/>
5.3. Data Portability:
- Upon request, we may provide you with a copy of your personal information in a structured, commonly used, and machine-readable format for portability purposes.</span>



<h5 className='mt-4'>6. Changes to this Privacy Policy:</h5>

<span className='mt-4'>


6.1. Policy Updates:
- We reserve the right to update this Privacy Policy periodically to reflect changes in our practices, legal requirements, or industry standards. We will notify you of material changes through prominent notices on our website or direct communications.
<br/><br/>
6.2. Effective Date:
- The effective date of the Privacy Policy indicates the date of the latest revision. Your continued use of our software portal and services constitutes acceptance of the updated Privacy Policy.</span>





<h5 className='mt-4'>7. Contact Us:</h5>

<span className='mt-4'>


If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at care@payrupya.in.

By using our software portal and services, you acknowledge and consent to the collection, processing, and use of your personal information as described in this Privacy Policy.</span>



<h5 className='mt-4'></h5>

<span className='mt-4'></span>





<h5 className='mt-4'></h5>

<span className='mt-4'></span>



<h5 className='mt-4'></h5>

<span className='mt-4'></span>












            </div>
        </div>
      </div>


      <Fotter className="mt-5"/> 
    </div>
    </div>
  )
}

export default PrivacyPolicy
 















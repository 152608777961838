import React from 'react'
import Fotter from './Fotter/Fotter'
import Navbar from '../Navbar'

const AntivirusPolicy = () => {
  return (
    <>
    <Navbar/>
   
    <div>
      <section style={{backgroundColor:'#081246'}}>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-lg-12 col-sm-12 text-center py-5'>
                    <h6 style={{color:'#FFFFFFB2'}}>Policies</h6>
                        <h2 className='my-4' style={{color:'#fff'}}>Antivirus Policy</h2>
                    <h6 style={{color:'#FFFFFFB2'}}>Last Updated : 11 September 2023</h6>
                </div>
            </div>
        </div>
      </section>


      <div className='container mt-5'>
        <div className='row'>
            <div className='col-lg-12 col-sm-12'>

                <h2>1. Introduction</h2>
                <h4 className='my-4'>1.1 Purpose</h4>

                <span>
                The purpose of this Antivirus Policy is to establish guidelines, responsibilities, and procedures for the effective management and use of antivirus software within Envitech Solutions & Technologies Private Limited, in alignment with ISO 27001:2009 Information Security Management System (ISMS) standards.
                </span>



                <h4 className='my-4'>1.2 Scope</h4>

                <span>
                This policy applies to all employees, contractors, third-party vendors, and any other individualswho have access to the organization's information systems and devices.
                </span>

                <h3 className='mt-4'>2. Antivirus Software Deployment</h3>
                <h5 className='mt-4'>2.1 Selection and Licensing</h5>
                <span className='mt-4'>Antivirus     software will be selected based on its effectiveness, compatibility with     the organization's systems, and compliance with ISO 27001 standards.</span>





                <h5 className='mt-4'>2.2 Installation</h5>

                <span className='mt-4'>

Antivirus     software will be installed on all organization-owned devices, including     servers, workstations, laptops, and mobile devices.<br/>
Installation     procedures will be well-documented.<br/><br/>

2.3 Regular Updates<br/>

Antivirus     software and signatures will be updated regularly to ensure protection     against the latest threats.<br/>
Updates     will be scheduled to minimize disruptions.

2.4 Configuration<br/><br/>

Antivirus     software will be configured to perform regular scans, including real-time     monitoring and scheduled scans.<br/>
Configuration     settings will be optimized for maximum protection and minimum impact on     system performance.
</span>



                <h5 className='mt-4'>3. Antivirus Management</h5>

                <span className='mt-4'>

3.1 Monitoring<br/><br/>
Antivirus     logs and reports will be regularly monitored to identify and respond to     potential threats.
Monitoring     activities will be conducted by designated security personnel.<br/>

3.2 Incident Response<br/><br/>
Procedures     for responding to antivirus alerts and incidents will be defined.
Infected     devices will be isolated, cleaned, and returned to service promptly.

3.3 Quarantine and Removal<br/>
Infected     files and devices will be quarantined and removed from the network to     prevent the spread of malware.
</span>


                <h5 className='mt-4'>4. User Responsibilities</h5>

                <span className='mt-4'>

4.1 Reporting<br/>
Users     are responsible for promptly reporting any suspected malware infections or     antivirus alerts to the IT department or designated IT support personnel.
<br/><br/>
4.2 Avoiding Risks<br/><br/>
Users     should avoid downloading files from untrusted sources or clicking on     suspicious links and email attachments.<br/>
External     media, such as USB drives, should be scanned for malware before use on     organization-owned devices.</span>



                <h5 className='mt-4'></h5>

                <span className='mt-4'></span>


                <h5 className='mt-4'></h5>

                <span className='mt-4'></span>



                <h5 className='mt-4'></h5>

                <span className='mt-4'></span>


                <h5 className='mt-4'></h5>

                <span className='mt-4'></span>



                <h5 className='mt-4'></h5>

                <span className='mt-4'></span>


                <h5 className='mt-4'>5. Compliance and Auditing</h5>

                <span className='mt-4'>

5.1 Compliance Monitoring<br/><br/>
Compliance     with this Antivirus Policy will be monitored through regular audits and     reviews.
Non-compliance     issues will be addressed promptly.

5.2 Policy Review<br/><br/>
This     Antivirus Policy will be reviewed at least annually or as required by     changes in the organization's structure or regulatory environment.<br/>
Ensure     that the policy remains aligned with ISO 27001 standards and industry best     practices.
</span>



                <h5 className='mt-4'>6. Training and Awareness</h5>

                <span className='mt-4'>



6.1 Employee Training<br/><br/>
Employees     will receive training on antivirus best practices, recognizing malware     threats, and incident reporting procedures.<br/>
Continuous     training and awareness programs will be conducted to enhance security     awareness.</span>














            </div>
        </div>
      </div>


      <Fotter className="mt-5"/> 
    </div>
    </>
  )
}

export default AntivirusPolicy



 




import React from 'react'
import Fotter from './Fotter/Fotter'
import Navbar from '../Navbar'

const UserLicenseAgreement = () => {
  return (
    <>
    <Navbar/>
   
    <div>
    <section style={{backgroundColor:'#081246'}}>
      <div className='container py-5'>
          <div className='row'>
              <div className='col-lg-12 col-sm-12 text-center py-5'>
                  <h6 style={{color:'#FFFFFFB2'}}>Policies</h6>
                      <h2 className='my-4' style={{color:'#fff'}}>User License Agreement</h2>
                  <h6 style={{color:'#FFFFFFB2'}}>Last Updated : 11 September 2023</h6>
              </div>
          </div>
      </div>
    </section>


    <div className='container mt-5'>
      <div className='row'>
          <div className='col-lg-12 col-sm-12'>

              <h4 className='my-4'>Effective Date: Last updated Date

</h4>

              <span>
              This User License Agreement ("Agreement") is entered into between Envitech Solutions and Technologies Private Limited ("Envitech," "we," "us," or "our") and the user ("User" or "you") of our software portal and related services. By accessing or using our software portal, you agree to be bound by the terms and conditions of this Agreement.

              </span>



              <h3 className='my-4'>1. Grant of License:</h3>
           

              <span>
              1.1. Subject to compliance with this Agreement, Envitech grants you a limited, non-exclusive, non-transferable license to access and use our software portal and related services for your personal or internal business purposes.
              <br/><br/>
1.2. You agree not to sublicense, redistribute, or resell any part of our software portal or related services without prior written consent from Envitech.



              </span>

              <h6 className='mt-4'>2. User Responsibilities:</h6> 




              <span className='mt-4'>   You are solely responsible for maintaining the confidentiality and security of your account credentials, including username and password. You agree not to share your account credentials with any third party or allow unauthorized access to your account.
              <br/><br/>
2.2. You agree to use our software portal and services in compliance with all applicable laws, regulations, and this Agreement. You further agree not to engage in any illegal, fraudulent, or unauthorized activities while using our platform.
<br/><br/>
2.3. You acknowledge and agree that any violation of this Agreement, including but not limited to fraudulent activities, misuse of services, or breach of security, may result in immediate termination of your account and suspension of access to our software portal. In such cases, Envitech reserves the right to hold your entire wallet balance until the matter is resolved.


</span>


<h5 className='mt-4'>3. Intellectual Property Rights:</h5>

<span className='mt-4'>3.1. Our software portal and related services, including but not limited to trademarks, logos, design, text, graphics, software code, and other intellectual property rights, are owned or licensed by Envitech and protected under applicable copyright, trademark, and other intellectual property laws.<br/><br/>

3.2. You agree not to copy, modify, distribute, or create derivative works based on our software portal or related services without prior written consent from Envitech.</span>

<h5 className='mt-4'>4. Termination:</h5>
<span className='mt-4'>
4.1. Envitech reserves the right to terminate or suspend your access to our software portal and services at any time, with or without cause, and without prior notice. Grounds for termination may include but are not limited to violation of this Agreement, breach of security, or illegal activities.
<br/><br/>
4.2. In the event of termination, you agree to immediately cease all use of our software portal and related services. Envitech shall not be liable to you or any third party for any damages or losses arising from such termination.
</span>




<h5 className='mt-4'> 5. Limitation of Liability: </h5>

5<span className='mt-4'> .1. In no event shall Envitech be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our software portal or related services, including but not limited to loss of data, loss of profits, or business interruption.</span>
<br/>
<h5 className='mt-4'> 6. Governing Law and Dispute Resolution: </h5><br/>

<span className='mt-4'> 6.1. This Agreement shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or relating to this Agreement shall be subject to the exclusive jurisdiction of the courts in Jaipur, Rajasthan.</span>

<h5 className='mt-4'> 7. Amendments: </h5>

<span className='mt-4'> 7.1. Envitech reserves the right to modify or amend this Agreement at any time without prior notice. Any changes will be effective immediately upon posting the updated Agreement on our website. Your continued use of our software portal and services constitutes acceptance of the modified Agreement.</span>

<h5 className='mt-4'>
    8. Entire Agreement:
    </h5>

    <span className='mt-4'>8.1. This Agreement constitutes the entire understanding between you and Envitech regarding your use of our software portal and related services and supersedes all prior or contemporaneous agreements and understandings, whether written or oral.</span>

    <h5 className='mt-4'>9. Contact Us:</h5>

    <span className='mt-4'>9.1. If you have any questions, concerns, or feedback regarding this Agreement or our software portal, please contact us at care@payrupya.in .<br/>

By accessing or using our software portal and related services, you acknowledge that you have read, understood, and agreed to be bound by the terms and conditions of this Agreement.
</span>







          </div>
      </div>
    </div>


    <Fotter className="mt-5"/> 
  </div>
  </>
  )
}

export default UserLicenseAgreement















 












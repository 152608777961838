import React from 'react'
import Navbar from '../../Navbar'
import first from "../../Components/img/Group 217.png"
import second from "../../Components/img/Group 210.png"
import three from "../../Components/img/Group 213.png"
import mission from "../../Components/img/Mission (1).png"
import vision from "../../Components/img/Eye Looking (1).png"
import Fotter from '../Fotter/Fotter'
import cardimg from '../img/Mask group.png'
import cardimg2 from '../img/image 6.png'
import cardimg3 from '../img/Mask group (1).png'
import cardimg4 from '../img/image 8.png'
import cardimg5 from '../img/image 9.png'
import img77 from '../img/Rectangle 139.png'
import '../../App.css'

const Aboutus = () => {
  return (
    <div className='bg-white'>
      <Navbar />

      <div style={{ height: 100 }}></div>
      <section >
        <div className='container'>
          <div className='row w-100' style={{ justifyContent: "center", alignItems: "center", }}>
            <h5 className='mt-5' style={{
              fontFamily: "Darker Grotesque",
              fontsize: "48px",
              fontWeight: "800",
              textAlign: "center",
              textShadow: '1px 1px #00000040',

            }}>
              We help Rural India to get connected with Technology
            </h5>

            <h5 style={{
              fontFamily: "Darker Grotesque",
              fontsize: "48px",
              fontWeight: "800",
              textAlign: "center",
            }}>by generating new Employment Opportunities</h5>
            <button style={{
              width: "auto",
              padding: "16px 44px 16px 44px",
              gap: "10px",
              borderRadius: "40px",
              opacity: "0px",
              color: "#fff",
              marginTop: 30,
              backgroundColor: "#0A1759",
              textShadow: '1px 1px #00000040',
            }}>
              View Opportunities
            </button>
          </div>


        </div>
      </section>

      <section className='container my-5'>
        <div className='row'>
          <div className='col-sm-12 col-md-4 col-lg-4 mt-5' style={{ justifyItems: "center", alignItems: "center", display: "flex" }}>
            <img src={first} className='first' style={{ width: "80%", height: "80%" }} />
          </div>
          <div className='col-sm-12 col-md-4 col-xl-4 mt-3' style={{ justifyItems: "center", alignItems: "center", display: "flex" }}>
            <img src={second} className='first' style={{ width: "90%", height: "90%" }} />
          </div>
          <div className='col-sm-12 col-md-4 col-xl-4 mt-1' style={{ justifyItems: "center", alignItems: "center", display: "flex" }}>
            <img src={three} className='first' style={{ width: "80%", height: "100%" }} />
          </div>
        </div>
      </section>





      <section>
        <div className="container py-3">
          <div className='row'>

            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='card border-0'>
                <div className='card-body'>
                  <h2 style={{ fontWeight: '600' }}>We are Driven <br />by Our Values</h2>

                  <button className='btn mt-4' style={{ backgroundColor: '#0A1759', color: '#fff', borderRadius: '12px', padding: '8px 12px' }}>View Opportunities</button>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='card border-0' style={{ backgroundColor: '#EDF0F4' }}>
                <div className='card-body borer-0 p-0 '>
                  <h2 style={{ fontWeight: '600' }} className='p-3'>Collaboration</h2>

                  <p className='px-3'>We look to empower growth across the board, including our Clients, Team Members & Partners. We Build Technology that Empowers</p>

                  <div className=' borer-0 p-0'  >
                    <img src={cardimg} style={{ width: '100%', backgroundColor: '#FF63ED', borderRadius: '180px 180px 0px 0px' }} />
                  </div>
                </div>

              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 mt-5'>
              <div className='card border-0' style={{ backgroundColor: '#EDF0F4' }}>
                <div className='card-body borer-0 p-0 '>
                  <h2 style={{ fontWeight: '600' }} className='p-3'>Growth Mindset</h2>

                  <p className='px-3'>We all work with only one goal, that is to deliver excellence and thrive for reliability, we make sure that our Growth is everyone’s Growth</p>

                  <div className=' borer-0 p-0'  >
                    <img src={cardimg2} className='p-5 py-0' style={{ width: '100%', backgroundColor: 'orange', borderRadius: '200px 200px 0px 0px', height: '240px' }} />
                  </div>
                </div>

              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 metaver' style={{ marginTop: '-50px' }}>
              <div className='card border-0' style={{ backgroundColor: '#EDF0F4' }}>
                <div className='card-body borer-0 p-0 '>
                  <h2 style={{ fontWeight: '600' }} className='p-3'>Selflessness</h2>

                  <p className='px-3'>We are not here to just bring on revenue, we are here to impact and elevate the standard of digitalization of entire India, we work with spirit of Selflessness.</p>

                  <div className=' borer-0 p-0'  >
                    <img src={cardimg3} className=' ' style={{ width: '100%', backgroundColor: 'lightblue', borderRadius: '200px 200px 0px 0px', height: '240px' }} />
                  </div>
                </div>

              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
              <div className='card border-0' style={{ backgroundColor: '#EDF0F4' }}>
                <div className='card-body borer-0 p-0 '>
                  <h2 style={{ fontWeight: '600' }} className='p-3'>Selflessness</h2>

                  <p className='px-3'>We are not here to just bring on revenue, we are here to impact and elevate the standard of digitalization of entire India, we work with spirit of Selflessness.</p>

                  <div className=' borer-0 p-0'  >
                    <img src={cardimg4} className='p-5 py-0 ' style={{ width: '100%', backgroundColor: '#f7f5ae', borderRadius: '200px 200px 0px 0px', height: '240px' }} />
                  </div>
                </div>

              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 mt-5 pt-5'>
              <div className='card border-0' style={{ backgroundColor: '#EDF0F4' }}>
                <div className='card-body borer-0 p-0 '>
                  <h2 style={{ fontWeight: '600' }} className='p-3'>Selflessness</h2>

                  <p className='px-3'>We are not here to just bring on revenue, we are here to impact and elevate the standard of digitalization of entire India, we work with spirit of Selflessness.</p>

                  <div className=' borer-0 p-0'  >
                    <img src={cardimg5} className='p-5 py-0 ' style={{ width: '100%', backgroundColor: '#8ae78c', borderRadius: '200px 200px 0px 0px', height: '240px' }} />
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </section>







      <section className='my-5'>
        <div className='container  abcef py-5'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <h2 className='text-center text-white py-4' style={{ fontWeight: '600' }}>Start your Success <br />Story with Us</h2>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button className='btn text-white px-5' style={{ backgroundColor: '#F23033', }}>Get Started</button>
              </div>
            </div>
          </div>
        </div>

      </section>


      <section className='my-5'>
        <div className='container    py-5'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <h2 className='  py-4' style={{ fontWeight: '600', color: '#1E1E1E' }}>About Us</h2>

              <p lassName='my-4' style={{ color: '#1E1E1E', fontSize: '18px' }}>Established in 2019, Payrupya since it’s Inception has always been an epitome of Excellence in empowering Rural Fintech Sector, we have made our excellence in critical financial technologies like : AEPS, UPI Pay in Solutions , Payout Solutions, Low Cost Travels, Mobile Recharge, NSDL PAN Card Services and much more... <br /><br />

                Under the able guidance of our promoters and our senior leadership , we have been able to set a benchmark in the industry standards and it’s general operating processes. </p>

              <img src={img77} className='mt-4' style={{ width: '100%' }} />
            </div>
          </div>
        </div>

      </section>

      <section className='my-5'>
        <div className='container    py-5'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <h2 className='  py-4' style={{ fontWeight: '600', color: '#1E1E1E' }}>Our Journey</h2>

            </div>
          </div>

        </div>

      </section>

      <div class="container py-5">
        <div class="row">

          <div class="col-md-12 col-lg-6 m-auto">
            <div id="tracking-pre"></div>
            <div id="tracking">
              <div class="tracking-list">
                <div class="tracking-item">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>

                  <div class="tracking-content"><div className='card border-0' style={{ boxShadow: '0px 0px 24px 0px #7E88AD40', }}>
                    <div className='card-body'>
                      <h5>2019 - Inception</h5>
                      <p >Started as NSDL PAN Card service provider in the name of LD Solution </p>
                    </div>
                  </div></div>
                </div>


                <div class="tracking-item">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-date"> </div>
                  <div class="tracking-content">
                    <div className='card border-0' style={{ boxShadow: '0px 0px 24px 0px #7E88AD40', }}>
                      <div className='card-body'>
                        <h5>2020 - Bounce Back</h5>
                        <p >Admist the unfortunate COVID 19 situation we were able to maintain the high quality of service with monthly volume of 10k pan card each month </p>
                      </div>
                    </div></div>
                </div>


                <div class="tracking-item">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-content"> <div className='card border-0' style={{ boxShadow: '0px 0px 24px 0px #7E88AD40', }}>
                    <div className='card-body'>
                      <h5>2021 - Version 2.0</h5>
                      <p >Rebranded to Payrupya and added multiple services like Travels, AEPS, recharge, bill payments, etc </p>
                    </div>
                  </div></div>
                </div>



                <div class="tracking-item">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-content"><div className='card border-0' style={{ boxShadow: '0px 0px 24px 0px #7E88AD40', }}>
                    <div className='card-body'>
                      <h5>2022 - Making Marks</h5>
                      <p >converted to Private Limited Company as requirements of high volume maintenance </p>
                    </div>
                  </div></div>
                </div>


                <div class="tracking-item">
                  <div class="tracking-icon status-current blinker">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-content"><div className='card border-0' style={{ boxShadow: '0px 0px 24px 0px #7E88AD40', }}>
                    <div className='card-body'>
                      <h5>2023 - Chapter Continues</h5>
                      <p >Continued to be best service provider in the industry growing the client database to 5000+ </p>
                    </div>
                  </div></div>
                </div>



                <div class="tracking-item">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-content"><div className='card border-0' style={{ boxShadow: '0px 0px 24px 0px #7E88AD40', }}>
                    <div className='card-body'>
                      <h5>2024 - Version 3.0</h5>
                      <p >Developing next gen platform which will overtake every traditional competitor and will make a dent in the history of Indian Fintech Sector </p>
                    </div>
                  </div></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>













































      <section className='container'>
        <div className='row p-1'  >
          <div className=' col-sm-12 col-md-4 col-xl-6 mt-3' >

            <div className='card' style={{ width: "100%", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
              <div className="card-body">
                <h5 className="" style={{
                  fontFamily: "Darker Grotesque",
                  fontSize: "64px",
                  fontWeight: 500,
                  lineHeight: "87.68px",
                  textAlign: "center"
                }}>Our Vision</h5>
                <p className="" style={{
                  fontFamily: "Space Grotesk",
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "32.88px",
                  textAlign: "center"
                }}>
                  To Become most looked after brand in the segment of Rural Fintech Sector and to be most excellent and affordable services provider out their
                </p>


                <img src={mission} alt='#' style={{ resize: "cover", width: '100%' }} />

              </div>
            </div>
          </div>

          <div className=' col-sm-12 col-md-4 col-xl-6 mt-3' >

            <div className='card' style={{ width: "100%", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
              <div className="card-body pb-4 ">
                <h5 className="" style={{
                  fontFamily: "Darker Grotesque",
                  fontSize: "64px",
                  fontWeight: 500,
                  lineHeight: "87.68px",
                  textAlign: "center"
                }}>Our Mission</h5>
                <p className="" style={{
                  fontFamily: "Space Grotesk",
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "32.88px",
                  textAlign: "center"
                }}>To onboard over 1,00,000+ Retailers on our Portal before 2030</p>

                <img src={vision} alt='#' className='py-4' style={{ objectFit: "cover", width: "100%" }} />

              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="mt-5 mb-5 have" style={{ backgroundImage: `url('img/Group 18.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 m-auto mt-5">
              <h2 className="text-center" style={{ fontWeight: 600 }}>Frequently Asked Questions (FAQs)</h2>

              <div className="accordion mt-5" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo11">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo11" aria-expanded="false" aria-controls="collapseTwo11">
                      <span>Who can Become DMT Agent ?</span>
                    </button>
                  </h2>
                  <div id="collapseTwo11" className="accordion-collapse collapse" aria-labelledby="headingTwo11" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>Who can Become DMT Agent ?</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>In Which bank accounts I can send money ?</span>
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>In Which bank accounts I can send money ?</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>What are the various charges for Domestic Money Transfer ?</span>
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>What are the various charges for Domestic Money Transfer ?</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree4">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree4" aria-expanded="false" aria-controls="collapseThree4">
                      <span>How can I change details in my PAN Card ?</span>
                    </button>
                  </h2>
                  <div id="collapseThree4" className="accordion-collapse collapse" aria-labelledby="headingThree4" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>How can I change details in my PAN Card ?</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree5">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree5" aria-expanded="false" aria-controls="collapseThree5">
                      <span>How to apply for new PAN Card ?</span>
                    </button>
                  </h2>
                  <div id="collapseThree5" className="accordion-collapse collapse" aria-labelledby="headingThree5" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>How to apply for new PAN Card ?</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree6">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree6" aria-expanded="false" aria-controls="collapseThree6">
                      <span>How can I withdraw money using aadhar Card ?</span>
                    </button>
                  </h2>
                  <div id="collapseThree6" className="accordion-collapse collapse" aria-labelledby="headingThree6" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <span>How can I withdraw money using aadhar Card ?</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}


      <Fotter />
    </div>
  )
}

export default Aboutus
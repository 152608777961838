import React from 'react';
import { NavLink } from 'react-router-dom'; // Assuming you're using React Router for navigation
import logo from '../src/Components/img/Rectangle 2 (1).png';
import { CiMenuBurger } from "react-icons/ci";

const Navbar = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 m-auto">
            <nav className="navbar navbar-expand-lg navbar-white bg-white fixed-top" style={{ boxShadow: '0px 4px 10.100000381469727px 0px #CAD3DD4F' }}>
              <div className="container">
                <NavLink className="navbar-brand" to="/">
                  <img src={logo} style={{ width: '130px' }} alt="Logo" />
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{ color: '#000' }}>
                  <span ><CiMenuBurger />
</span> 
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                      <NavLink className="nav-link" to="/About">About Us</NavLink>
                    </li>
                    <li className="nav-item dropdown">
                      <NavLink className="nav-link dropdown-toggle" to="/service" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Services
                      </NavLink>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><NavLink to="/service" className="dropdown-item">BBPS</NavLink></li>
                        <li><NavLink to="/service" className="dropdown-item">AEPS</NavLink></li>
                        <li><NavLink to="/service" className="dropdown-item">NSDL PAN</NavLink></li>
                        <li><NavLink to="/service" className="dropdown-item">Travels Booking</NavLink></li>
                      </ul>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Products
                      </NavLink>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><span className="dropdown-item">Action</span></li>
                        <li><span className="dropdown-item">Another action</span></li>
                        <li><hr className="dropdown-divider"/></li>
                        <li><span className="dropdown-item">Something else here</span></li>
                      </ul>
                    </li> */}
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Pricing">Pricing</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Contact_us" >
                        <i className="fa-solid fa-headset pe-2"></i>Contact Us
                      </NavLink>
                    </li>
                  </ul>
                  <div className="d-flex ABCD"> 
                    <button type="button" className="Login me-3">Login<i className="fa-solid fa-chevron-right ms-1" style={{ color: '#ffffff', fontSize: '13px' }}></i></button>
                    <button type="button" className="Reg"><NavLink to="/Login">Register <i className="fa-solid fa-chevron-right ms-1" style={{ color: '#0A2540', fontSize: '13px' }}></i></NavLink></button>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Navbar;

import React from 'react'
import Fotter from './Fotter/Fotter'
import Navbar from '../Navbar'

const EnverPolicy = () => {
  return (
    <div>
      <Navbar/>
    <div>
    <section style={{backgroundColor:'#081246'}}>
      <div className='container py-5'>
          <div className='row'>
              <div className='col-lg-12 col-sm-12 text-center py-5'>
                  <h6 style={{color:'#FFFFFFB2'}}>Policies</h6>
                      <h2 className='my-4' style={{color:'#fff'}}>Environmental Policy </h2>
                  <h6 style={{color:'#FFFFFFB2'}}>Last Updated : 11 September 2023</h6>
              </div>
          </div>
      </div>
    </section>


    <div className='container mt-5'>
      <div className='row'>
          <div className='col-lg-12 col-sm-12'>

              <h4 className='my-4'>Effective Date: Last Updated Date
</h4>

              <span>
              Envitech Solutions and Technologies Private Limited ("Envitech," "we," "us," or "our") is committed to minimizing our environmental impact and promoting sustainability in all aspects of our operations, including our software portal and related services. This Environmental Policy outlines our commitment to environmental responsibility and sets forth specific guidelines for our business partners, master distributors, distributors, retailers, and all individuals associated with PayRupya.


              </span>



              <h3 className='my-4'>1. Environmental Commitment:

</h3>
           

              <span>
1.1. Envitech is dedicated to reducing our carbon footprint, conserving natural resources, and mitigating environmental risks associated with our operations.

1.2. We strive to comply with all applicable environmental laws, regulations, and standards, and to continuously improve our environmental performance.



              </span>

              <h6 className='mt-4'>2. Environmental Guidelines for Business Partners, Master Distributors, Distributors, Retailers, and Personnel:

</h6> 




              <h5 className='mt-4'>  2.1. Waste Management:</h5>
              <span className='mt-4'> - We encourage our business partners, master distributors, distributors, retailers, and personnel to minimize waste generation and promote recycling and responsible disposal practices.
   - All individuals associated with PayRupya are expected to properly segregate waste, recycle materials whenever possible, and dispose of hazardous waste in accordance with applicable regulations.
   - Data Point: Regular waste audits will be conducted to measure waste generation and identify opportunities for waste reduction.</span>
                  
            


              <h5 className='mt-4'>2.2. Energy Efficiency:</h5>

              <span className='mt-4'>
   - We promote energy efficiency and conservation measures to reduce energy consumption in our operations.
   - Business partners, master distributors, distributors, retailers, and personnel are encouraged to adopt energy-efficient practices, such as using energy-saving devices, optimizing equipment performance, and reducing unnecessary energy consumption.
   - Data Point: Energy usage will be monitored regularly, and energy-saving initiatives will be implemented based on the findings of energy audits.</span>



              <h5 className='mt-4'>2.3. Carbon Emissions:</h5>

              <span className='mt-4'>
   - We are committed to reducing carbon emissions associated with our activities, including transportation and energy use.
   - Our business partners, master distributors, distributors, retailers, and personnel are encouraged to minimize carbon emissions by utilizing eco-friendly transportation options, reducing vehicle idling, and implementing energy-efficient technologies.
   - Data Point: Carbon footprint assessments will be conducted annually to track emissions and identify areas for improvement.</span>



              <h5 className='mt-4'>2.4. Sustainable Procurement:</h5>

              <span className='mt-4'>

   - We prioritize the procurement of environmentally friendly products and materials whenever feasible.
   - Business partners, master distributors, distributors, retailers, and personnel are encouraged to consider environmental factors when selecting suppliers and products, such as sustainability certifications, eco-friendly packaging, and recycled content.
   - Data Point: Sustainable procurement metrics will be incorporated into supplier evaluation processes to promote environmentally responsible sourcing practices.</span>



              <h5 className='mt-4'>2.5. Environmental Awareness:</h5>

              <span className='mt-4'> 
   - We promote environmental awareness and education among our business partners, master distributors, distributors, retailers, and personnel.
   - Training programs, workshops, and informational materials are provided to raise awareness about environmental issues and foster a culture of sustainability.
   - Data Point: Environmental training participation rates and feedback will be monitored to evaluate the effectiveness of awareness initiatives.
 
</span>



              <h5 className='mt-4'>3. Compliance and Continuous Improvement:</h5>

              <span className='mt-4'>
3.1. Envitech is committed to complying with all applicable environmental laws, regulations, and standards.
   - Business partners, master distributors, distributors, retailers, and personnel are expected to adhere to environmental laws and regulations relevant to their activities.
   - Data Point: Compliance audits will be conducted regularly to ensure adherence to environmental regulations and standards.

3.2. We are dedicated to continuously improving our environmental performance and implementing best practices to minimize our environmental impact.
   - Feedback and suggestions for improving environmental practices are welcomed from all stakeholders.
   - Data Point: Key performance indicators (KPIs) will be established to measure progress towards environmental goals, and regular progress reports will be issued to stakeholders.</span>



              <h5 className='mt-4'>4. Communication and Reporting:</h5>

              <span className='mt-4'>

4.1. Envitech will communicate our environmental policy and guidelines to our business partners, master distributors, distributors, retailers, and personnel.
   - Regular updates and reminders will be provided to ensure awareness and compliance.
   - Data Point: Communication effectiveness will be assessed through feedback surveys and participation rates.

4.2. We will report on our environmental performance periodically, including progress towards environmental goals and initiatives.
   - Transparent reporting helps to track our environmental impact and identify areas for improvement.
   - Data Point: Environmental performance reports will be published annually and made available to stakeholders.</span>



              <h5 className='mt-4'>5. Contact Us:</h5>

              <span className='mt-4'>

5.1. If you have any questions, concerns, or suggestions regarding our Environmental Policy or environmental practices, please contact us at care@payrupya.in .

By working together to implement these environmental guidelines, we can contribute to a healthier planet and a sustainable future for generations to come.


This Environmental Policy reflects our commitment to environmental responsibility and sets forth specific guidelines for our business partners, master distributors, distributors, retailers, and all individuals associated with PayRupya. We encourage active participation and collaboration in implementing sustainable practices to protect the environment.</span>



          </div>
      </div>
    </div>


    <Fotter className="mt-5"/> 
  </div>
  </div>
  )
}

export default EnverPolicy






import React from 'react'
import Fotter from './Fotter/Fotter'
import Navbar from '../Navbar'

const RefundPolicy = () => {
  return (
    <div>
      <Navbar/>
       <div>
      <div>
      <section style={{backgroundColor:'#081246'}}>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-lg-12 col-sm-12 text-center py-5'>
                    <h6 style={{color:'#FFFFFFB2'}}>Policies</h6>
                        <h2 className='my-4' style={{color:'#fff'}}>Refund Policy</h2>
                    <h6 style={{color:'#FFFFFFB2'}}>Last Updated : 11 September 2023</h6>
                </div>
            </div>
        </div>
      </section>


      <div className='container mt-5'>
        <div className='row'>
            <div className='col-lg-12 col-sm-12'>
 
                <h4 className='my-4'>Effective Date: Last Updated Date</h4>

                <span>
                Envitech Solutions and Technologies Private Limited ("Envitech," "we," "us," or "our") is dedicated to ensuring customer satisfaction and providing transparent guidelines regarding refunds for transactions made through our software portal and related services. This Refund Policy outlines our procedures for refund eligibility and processes.

                </span>



                <h3 className='my-4'>1. Refund Eligibility:
</h3>
             

                <span>1.1. Our refund policy encompasses various types of transactions and services, each subject to specific criteria. Generally, refunds may be issued under the following circumstances:
   - Service Disruption: Should technical issues on our end result in service disruption or unavailability, we may issue refunds for affected transactions.
   - Billing Errors: If you suspect an incorrect charge or billing error, you may request a refund, subject to verification.
   - Product Defects: If a purchased product is defective or fails to meet expectations, a refund may be considered upon the return of the product in its original condition.


                </span>

                <h6 className='mt-4'>1.2. However, certain fees and transactions are non-refundable, including but not limited to:
</h6> 




                <span className='mt-4'>   - ID Activation Fees: Fees paid for ID Activation at any level are non-refundable under any circumstances.
                - Transactions Associated with Third-party APIs: Transactions involving third-party APIs such as BBPS (Bharat Bill Payment System), AEPS (Aadhaar Enabled Payment System), or any services implemented through third-party APIs are non-refundable and not the liability of our organization. Disputes arising from such transactions should be addressed directly with the third-party service provider.


                1.3. Additionally, refunds may not be applicable for:
   - Change of Mind: Refunds will not be issued for transactions resulting from a change of mind or personal preference.
   - Misuse or Abuse: Refunds will not be granted for transactions involving misuse or abuse of our services.

</span>








<h5 className='mt-4'>2. Refund Process:</h5>

<span className='mt-4'>

2.1. To initiate a refund request, please contact our customer support team within the specified timeframe for the transaction type.

2.2. When requesting a refund, please provide pertinent details such as transaction ID, invoice number, and a brief explanation of the reason for the refund request.

2.3. Our customer support team will review the request and assess eligibility based on our refund policy. We reserve the right to deny requests that do not meet specified criteria.

2.4. Upon approval, refunds will be processed within a reasonable timeframe using the original payment method. Please allow additional time for the refund to reflect in your account, as processing times may vary by financial institution.</span>



<h5 className='mt-4'>3. Refund Timeframe:</h5>

<span className='mt-4'>

3.1. Refunds will typically be processed within [insert timeframe] business days from approval. However, please be aware that the timeframe for funds to appear in your account may vary based on your financial institution's policies.</span>



<h5 className='mt-4'>4. Refund Policy Updates:</h5>

<span className='mt-4'>


4.1. We reserve the right to update or modify this Refund Policy without prior notice. Any changes will be effective immediately upon posting the revised policy on our website. Your continued use of our software portal and services constitutes acceptance of the updated refund policy.</span>



<h5 className='mt-4'>5. Contact Us:</h5>

<span className='mt-4'>

5.1. Should you have any questions, concerns, or require assistance regarding our refund policy, please reach out to our customer support team at care@payrupya.in.</span>



<h5 className='mt-4'>6. Review and Understand:</h5>

<span className='mt-4'>

6.1. We encourage you to review and understand this policy before conducting transactions through our software portal. If you have any inquiries or concerns, please do not hesitate to contact us for further assistance.</span>



            </div>
        </div>
      </div>


      <Fotter className="mt-5"/> 
    </div>
    </div>
    </div>
  )
}

export default RefundPolicy


















